<template>
  <div>
    <a-card title="企名片团队数据" style="margin-bottom: 20px">
      <a-button
        type="primary"
        slot="extra"
        @click="handleUpdate"
        v-if="hasAction('update-external-data')"
      >
        更新团队数据
      </a-button>
      <transition name="fold-content">
        <div v-show="selectedRowKeys.length > 0" class="multiple-box">
          <span class="tip"
            >已选择<span class="num">{{ selectedRowKeys.length }}</span
            >项</span
          >
          <a-divider type="vertical" />
          <span class="btn" @click="cancelSelect">取消选择</span>
          <a-divider type="vertical" v-if="hasAction('edit-company-info')" />
          <span
            class="btn"
            @click="handleCopy"
            v-if="hasAction('edit-company-info')"
            >复制到分享起源</span
          >
        </div>
      </transition>
      <a-table
        :columns="qmpColumns"
        :row-key="(record) => record.origin + record.id"
        :dataSource="qmpData"
        :row-selection="rowSelection"
        :loading="loading"
      >
      </a-table>
    </a-card>
    <a-card v-for="item in data" :key="item.id">
      <span slot="title">{{ item.orgName }}-{{ item.groupName }}团队数据</span>
      <a-button
        v-if="hasAction('edit-company-info')"
        type="primary"
        icon="plus"
        @click="handleAdd(item.groupId)"
      >
        添加成员
      </a-button>
      <a-table
        :columns="columns"
        :dataSource="item.teamMembers"
        :loading="loading"
        rowKey="id"
      >
        <template slot="dataManage" slot-scope="text, record">
          <div>
            <a @click="handleEdit(record, item.groupId)">修改</a>
            <a-divider type="vertical" />
            <a-popconfirm
              title="确认移除成员吗？"
              ok-text="是"
              cancel-text="否"
              @confirm="handleDelete(record)"
            >
              <a>删除</a>
            </a-popconfirm>
          </div>
        </template>
      </a-table>
    </a-card>
  </div>
</template>

<script>
import {
  getTeamList,
  deleteTeam,
  addTeam,
  updateTeamFromQMP,
} from "@/api/company";
import CopyForm from "./components/CopyForm";
import TeamForm from "./components/TeamForm";

const columns = [
  {
    title: "成员名称",
    dataIndex: "name",
  },
  {
    title: "职称",
    dataIndex: "title",
  },
  {
    title: "性别",
    dataIndex: "gender",
    customRender: (text) => {
      if (text === "MALE") return "男";
      else if (text === "FEMALE") return "女";
    },
  },
  {
    title: "简介",
    dataIndex: "description",
    ellipsis: true,
    width: "25vw",
  },
  {
    title: "出生年份",
    dataIndex: "birthYear",
  },
  {
    title: "是否离职",
    dataIndex: "dimission",
    customRender: (text) => {
      if (text === "YES") return "是";
      else if (text === "NO") return "否";
    },
  },
];
const qmpColumns = [
  {
    title: "成员名称",
    dataIndex: "name",
  },
  {
    title: "职称",
    dataIndex: "title",
  },
  {
    title: "简介",
    dataIndex: "description",
    ellipsis: true,
    width: "30vw",
  },
  {
    title: "是否离职",
    dataIndex: "dimission",
    customRender: (text) => {
      if (text === "YES") return "是";
      else if (text === "NO") return "否";
    },
  },
];

export default {
  name: "team-manage",
  data() {
    return {
      companyId: this.$route.params.companyId,
      data: [],
      qmpData: [],
      groups: [],
      selectedRows: [],
      selectedRowKeys: [],
      columns,
      qmpColumns,
      loading: false,
    };
  },
  computed: {
    rowSelection() {
      return {
        selectedRowKeys: this.selectedRowKeys,
        onChange: this.onSelectChange,
      };
    },
  },
  created() {
    if (this.hasAction("edit-company-info") && this.columns.length < 7) {
      this.columns.push({
        title: "管理",
        dataIndex: "team-manage",
        scopedSlots: {
          customRender: "dataManage",
        },
      });
    }
    this.loadData();
  },
  methods: {
    loadData() {
      this.loading = true;
      this.data = [];
      this.qmpData = [];
      this.selectedRowKeys = [];
      this.selectedRows = [];
      getTeamList({
        companyId: this.companyId,
      }).then((res) => {
        res.data.forEach((item) => {
          if (item.groupName === "QMP") {
            item.teamMembers.forEach((i) => {
              this.qmpData.push(i);
            });
          } else {
            this.data.push(item);
          }
        });
        this.data.forEach((item) => {
          this.groups.push({
            id: item.groupId,
            name: item.orgName + "-" + item.groupName,
          });
        });
        this.loading = false;
      });
    },
    handleAdd(groupId) {
      this.$dialog(
        TeamForm,
        {
          record: { companyId: this.companyId },
          groupId,
          on: {
            ok: this.loadData,
            cancel() {},
            close() {},
          },
        },
        {
          title: "新增",
          width: 700,
          height: 500,
          centered: true,
          maskClosable: false,
        }
      );
    },
    handleEdit(record, groupId) {
      this.$dialog(
        TeamForm,
        {
          record,
          groupId,
          on: {
            ok: this.loadData,
            cancel() {},
            close() {},
          },
        },
        {
          title: "更新",
          width: 700,
          height: 500,
          centered: true,
          maskClosable: false,
        }
      );
    },
    handleDelete(record) {
      deleteTeam(record).then(() => {
        this.loadData();
        this.$message.success("删除成功！");
      });
    },
    onSelectChange(selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys;
      this.selectedRows = selectedRows;
    },
    removeSelecedtKey(key) {
      let index = this.selectedRowKeys.findIndex((item) => item === key);
      this.selectedRowKeys.splice(index, 1);
      this.selectedRows.splice(index, 1);
    },
    cancelSelect() {
      this.selectedRowKeys = [];
      this.selectedRows = [];
    },
    handleCopy() {
      let _this = this;
      this.$dialog(
        CopyForm,
        {
          groups: this.groups,
          on: {
            copy: (ids) => {
              _this.copy(ids);
            },
          },
        },
        {
          title: "复制到分享起源",
          width: 450,
          centered: true,
          maskClosable: false,
        }
      );
    },
    copy(ids = []) {
      if (ids.length == 0) return;
      let promiseList = [];
      ids.forEach((id) => {
        this.selectedRows.forEach((item) => {
          promiseList.push(
            addTeam({
              companyId: item.companyId,
              description: item.description,
              dimission: item.dimission,
              name: item.name,
              title: item.title,
              origin: "SCP",
              birthYear: item.birthYear,
              gender: item.gender,
              groupId: id,
            })
          );
        });
      });
      Promise.all(promiseList).then(() => {
        this.loadData();
      });
    },
    handleUpdate() {
      this.$dialog(
        {
          render() {
            return (
              <div>
                此更新功能需要调用企名片团队数据接口，会消耗一定资源且覆盖原有信息资料，请认真核查后再决定是否更新公司团队数据。
              </div>
            );
          },
        },
        {
          on: {
            ok: () => {
              updateTeamFromQMP({ companyId: this.companyId }).then((res) => {
                if (res.code === 0) {
                  this.$message.success("更新企名片数据成功！");
                  this.loadData();
                }
              });
            },
          },
        },
        {
          title: "提示",
          okText: "确定更新",
          width: 400,
          height: 200,
        }
      );
    },
  },
};
</script>

<style lang="less" scoped>
.multiple-box {
  height: 40px;
  background: #1890ff;
  line-height: 40px;
  color: #fff;
  padding: 0 20px;
  .tip {
    margin-right: 10px;
    color: #e8e8e8;
  }
  .num {
    color: #fff;
    font-weight: bold;
    margin: 0 3px;
  }
  .btn {
    margin: 0 10px;
    cursor: pointer;
  }
  .ant-divider {
    background: #a9a9a9;
  }
}
</style>
