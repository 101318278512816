<template>
  <div>
    <div>确定将所选成员信息复制到分享起源的团队数据中？</div>
    <div>
      <a-select
        mode="multiple"
        placeholder="请选择用户组"
        :value="selectedValue"
        style="width: 400px"
        @change="handleChange"
      >
        <a-select-option v-for="group in groups" :key="group.id">
          {{ group.name }}
        </a-select-option>
      </a-select>
    </div>
  </div>
</template>

<script>
export default {
  name: "CopyForm",
  components: {},
  props: {
    groups: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      selectedValue: [],
    };
  },
  created() {
    this.loadData();
  },
  methods: {
    loadData() {},
    handleChange(values) {
      this.selectedValue = values;
    },
    onOk() {
      return new Promise((resolve) => {
        this.$emit('copy',this.selectedValue)
        resolve(true);
      });
    },
  },
};
</script>

<style scoped></style>
