<template>
  <a-form :form="form">
    <a-form-item label="姓名" :labelCol="labelCol" :wrapperCol="wrapperCol">
      <a-input
        v-decorator="['name', { initialValue: record.name }]"
        placeholder="请输入姓名"
      />
    </a-form-item>
    <a-form-item label="职称" :labelCol="labelCol" :wrapperCol="wrapperCol">
      <a-input
        v-decorator="['title', { initialValue: record.title }]"
        placeholder="请输入职称"
      />
    </a-form-item>
    <a-form-item label="性别" :labelCol="labelCol" :wrapperCol="wrapperCol">
      <a-select style="width: 120px" placeholder="请选择" v-decorator="['gender',{ initialValue: record.gender }]" >
        <a-select-option value="MALE">
          男
        </a-select-option>
        <a-select-option value="FEMALE">
          女
        </a-select-option>
      </a-select>
    </a-form-item>
    <a-form-item label="出生年份" :labelCol="labelCol" :wrapperCol="wrapperCol">
      <year-picker v-decorator="['birthYear']" :setDefuat="record.birthYear||null"></year-picker>
    </a-form-item>
    <a-form-item label="是否离职" :labelCol="labelCol" :wrapperCol="wrapperCol">
      <a-radio-group
        v-decorator="['dimission', { initialValue: record.dimission }]"
      >
        <a-radio value="YES">是</a-radio>
        <a-radio value="NO">否</a-radio>
      </a-radio-group>
    </a-form-item>
    <a-form-item label="简介" :labelCol="labelCol" :wrapperCol="wrapperCol">
      <a-text-area
        v-decorator="['description', { initialValue: record.description }]"
        placeholder="请输入个人简介"
        rows="8"
      />
    </a-form-item>
  </a-form>
</template>

<script>
import { addTeam, updateTeam } from "@/api/company";
import { Input } from "ant-design-vue";
import YearPicker from '@/components/Selectors/YearPicker'

export default {
  name: "TaskForm",
  props: {
    record: {
      type: Object,
      default: null
    },
    groupId:{
      type:Number,
      require:true
    }
  },
  components: {
    ATextArea: Input.TextArea,
    YearPicker
  },
  data() {
    return {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 7 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 13 },
      },
      form: this.$form.createForm(this),
    };
  },
  methods: {
    onOk() {
      return new Promise((resolve) => {
        this.form.validateFields((err, values) => {
          if (!err) {
            let member = values;
            let formData = {
                description: member.description,
                dimission: member.dimission,
                name: member.name,
                title: member.title,
                birthYear:member.birthYear&&+member.birthYear.format('YYYY'),
                gender:member.gender,
                groupId:this.groupId,
                origin:'SCP'
              };
            if (this.record.id) {
              // 更新
              formData.id = this.record.id
              updateTeam(formData).then(() => {
                this.$message.success("更新成功！");
                resolve(true);
              });
            } else {
              // 创建
              formData.companyId = this.record.companyId
              addTeam(formData).then(() => {
                this.$message.success("添加成功！");
                resolve(true);
              });
            }
          }
        });
      });
    },
    onCancel() {
      return new Promise((resolve) => {
        resolve(true);
      });
    },
  },
};
</script>

<style scoped>
/* tile uploaded pictures */
.upload-list-inline >>> .ant-upload-list-item {
  float: left;
  width: 200px;
  margin-right: 8px;
}
.upload-list-inline >>> .ant-upload-animate-enter {
  animation-name: uploadAnimateInlineIn;
}
.upload-list-inline >>> .ant-upload-animate-leave {
  animation-name: uploadAnimateInlineOut;
}
</style>
